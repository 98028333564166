<template>
    <div  class="filter" style="margin-bottom:30px;">
        <div class="filter-tips">
            <span class="span-txt">筛选条件</span>
        </div>
        <div v-loading="loadingShow" class="padding20" style="overflow: hidden;position: relative;" :style="{'height':isQueryShow?'auto':'180px'}">
            <div class="mb-10" v-if="Object.keys(enterpriseQuery).length>0?true:false" style="position:relative;">
                <span class="search-t">已选条件：</span> 
                <div class="search-content">
                    <el-tag
                        :key="tag"
                        v-for="(tag,key) in enterpriseQuery"
                        class="mr-5"
                        closable
                        :disable-transitions="false"
                        @close="handleCloseFun(tag,key)">
                        {{tag.name}}
                    </el-tag>
                </div>
                <span class="filter_delete" @click="clearQueryFun"><i class="iconfont ec-icon-delete"></i>清除全部</span>
            </div>
            <div class="mb-10">
                <span class="search-t">关键词：</span> 
                <div class="search-content">
                    <el-input
                    placeholder="企业名称"
                    prefix-icon="iconfont ec-icon-search"
                    @keyup.enter="queryWhereFun('search','')"
                    v-model="iptSearch">
                    </el-input>
                </div>
            </div>
            <div class="mb-10" v-if="regionList && regionList.length>0?true:false">
                <span class="search-t">
                    {{regionTitle+'：'}}
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in regionList" :key="item.code"  @click="queryWhereFun('region',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="mb-10" v-if="econoIndustryClass==null?false:true">
                <span class="search-t">
                    行业分类：
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in econoIndustryClass" :key="item.code"  @click="queryWhereFun('econoIndustry',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('registeredCapital')?false:true">
                <span class="search-t">注册资本：</span> 
                <div class="search-content">                                                                                                                                                                                    
                    <ul>
                        <li v-for="item in regCapitalList" :key="item.code" @click="queryWhereFun('registeredCapital',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('createDate')?false:true">
                <span class="search-t">成立日期：</span> 
                <div class="search-content">
                    <ul>
                        <li v-for="item in estiblishTimeList" :key="item.code" @click="queryWhereFun('createDate',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            
            <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('epStyle')?false:true">
                <span class="search-t">企业类型：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in enterpriseType" :key="item.code" @click="queryWhereFun('epStyle',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            
            <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('insuredPerson')?false:true">
                <span class="search-t">参保人数：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in insuredPersonList" :key="item.code" @click="queryWhereFun('insuredPerson',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <!-- <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('state')?false:true">
                <span class="search-t">企业状态：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in stateList" :key="item.code" @click="queryWhereFun('state',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div> -->
            <div class="mb-10" v-if="enterpriseQuery.hasOwnProperty('labelParentId')?false:true">
                <span class="search-t">企业标签：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in labelParentList" :key="item.code" @click="queryWhereFun('labelParentId',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if='!isQueryShow' class="expanded-mask"></div>
        <div class="expand-btn" @click="isQueryShow=!isQueryShow"><i class="iconfont" :class="{'ec-icon-arrow-bottom-bold':!isQueryShow,'ec-icon-arrow-top-bold':isQueryShow}"></i></div>
    </div>
    
    <div v-show="loadingShow" v-loading="loadingShow" element-loading-text="正在加载中..." class="filter" style="height:500px;">
    </div>
    <div v-show="!loadingShow"  class="filter">
        <div class="result-tips clearfix">
            <span>找到 <span>{{total}}</span> 条相关结果</span>
            <!-- <el-button type="primary" @click='download' size="small" round>导出数据</el-button> -->
        </div>
        <div>
            <ul class="result-list">
                <li v-for="o in enterpriseList" :key="o">
                    <el-image :src="o.logoUrl" class="ep-logo" style="margin-left:10px;">
                        <template #error>
                            <div class="image-slot">
                            <i class="iconfont ec-icon-image" style="font-size:2rem;"></i>
                            </div>
                        </template>
                    </el-image>
                    <div class='result-list-content' >
                        <el-link :underline="false" :href="`/#/ep/epAnalysis?id=`+o.companyId" 
                        target="_blank"  class="ep-link">{{o.name}}</el-link>
                        <span v-if='o.state' class="tag_common" :class="{'tag_risk_bg':',2,3,4,5,'.indexOf(','+o.state+',')>=0,'tag_normal_bg':',1,6,7,'.indexOf(','+o.state+',')>=0}">{{stateList.find(t=>t.id==o.state).name}}</span>
                        <div>
                            <el-tag  v-for="label in labelList.filter(t=>t.companyId==o.companyId)" :key="label.labelId"  class="m-5" size='small'>{{label.labelName}}</el-tag>
                        </div>
                        <div style="line-height:30px;">
                            <span class="paddingright20">法人：<span class="txt-color">{{o.legalPerson}}</span></span>
                            <span class="paddingright20" >注册资本：{{o.registeredcapital}}万{{o.capitaltype}} </span>
                            <span class="paddingright20">成立日期：{{parseTime( new Date(o.startDate),'{y}-{m}-{d}')}}</span>
                            <span class="paddingright20">企业类型：{{o.epstyle}}</span>
                            <br>
                            <span>地址：{{o.address}}</span>
                        </div>
                    </div>
                
                </li>
            </ul>
            <el-pagination
               class="page-cus"
                @current-change="handleCurrentChangeFun"
                :current-page="currentPage"
                :hide-on-single-page="total<pageSize?true:false"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <div v-if='total<1 && !isMuchOfTotal'>
            <div class="no_data_warp">
                <div class="no_data_top">
                    <img src="@/assets/no-data.png" alt="no-data">
                    <div>
                        <div class="no_data_title">抱歉，没有找到相关企业！</div>
                        <p>更换筛选条件，重新搜索</p>

                    </div>
                </div>
            </div>
        </div>
        <div  v-else-if='isMuchOfTotal'>
            <div class="no_data_warp">
                <div style="padding-top:120px;text-align: center;">
                    
                    <i class="iconfont ec-icon-nodata no_data_icon"></i>

                    <p>您的关键词搜索太宽泛，建议更精准下关键词</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
//import {nextTick}from 'vue'
import {getEpStyle,getLabelList,getRegionEpList,exportEpList,getEpState,getLabelListOfRegionEp,getRegionEpCount} from '@/http/epApi.js'
import {getNextCodeNicList,getCodeRegionList,getRegionInfo,addUserLog} from '@/http/basicsApi.js'
import store from '../../store'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { ElLoading } from 'element-plus';
import {parseTime} from '@/utils/index.js'
import { useRouter } from 'vue-router'
//import { useRoute } from 'vue-router'
//import axios from 'axios'
export default {
    name:'EpList',
    setup() {
        //const route=useRoute();
        const router=useRouter();
        //选中地区信息
        let regionCode=computed(()=> store.state.curRegionCode);
        let province='';//省份
        let city='';//城市
        let isQueryShow=ref(false);//是否完全显示筛选条件
        let loadingShow=ref(true);//数据加载
        let currentPage=ref(1);//当前页
        let pageSize=ref(10);//每页显示的行数
        let total=ref(0);//总行数
        let isMuchOfTotal=ref(false);//关键字搜索时，搜索过多，设置门槛为50，多余50则认为不够精确，重新输入要搜索的关键词
        //已筛选条件
        let enterpriseQuery=reactive({
        })
        //关键词：企业名称、统一社会信用代码
        let iptSearch=ref('');//关键词
        //注册资本
        const regCapitalList=reactive([
            {code:"0,100",name:"0~100万"},
            {code:"100,200",name:"100~200万"},
            {code:"200,500",name:"200~500万"},
            {code:"500,1000",name:"500~1000万"},
            {code:"1000",name:"1000万以上"}
        ])
        //成立日期,code的第一个元素表示年，后面两位表示时间间隔
        const curDate=new Date();
        const year=curDate.getFullYear();
        const estiblishTimeList=reactive([
            {code:parseTime(curDate.setFullYear(year-1),'{y}-{m}-{d}')+','+parseTime(curDate,'{y}-{m}-{d}'),name:"成立1年内"},
            {code:parseTime(curDate.setFullYear(year-5),'{y}-{m}-{d}')+','+parseTime(curDate.setFullYear(year-1),'{y}-{m}-{d}'),name:"成立1～5年"},
            {code:parseTime(curDate.setFullYear(year-10),'{y}-{m}-{d}')+','+parseTime(curDate.setFullYear(year-5),'{y}-{m}-{d}'),name:"成立5～10年"},
            {code:parseTime(curDate.setFullYear(year-15),'{y}-{m}-{d}')+','+parseTime(curDate.setFullYear(year-10),'{y}-{m}-{d}'),name:"成立10～15年"},
            {code:parseTime(curDate.setFullYear(year-15),'{y}-{m}-{d}'),name:"成立15年以上"}
        ])
        //获取企业类型
        let enterpriseType=ref(null);//企业类型
        const getEnterpriseTypeFun= async ()=>{
            let result=await getEpStyle();
            if(result.code==20000){
                enterpriseType.value=result.data.filter(t=>t.id!=6);
            }
            //console.log(enterpriseType.value);
        }

        //参保人数
        const insuredPersonList=reactive([
            {code:"0,100",name:"小于100人"},
            {code:"100,499",name:"100-499人"},
            {code:"500,999",name:"500-999人"},
            {code:"1000,4999",name:"1000-4999人"},
             {code:"5000,9999",name:"5000-9999人"},
              {code:"10000",name:"10000人以上"}
        ])

        let econoIndustryClass=ref(null);//行业分类
        //获取行业分类
        const getEconoIndustryClassFun= async (item)=>{
            //console.log(item);
            if(parseInt(item.level)>=2){
                econoIndustryClass.value=null;
                return;
            }
            //获取下级行业分类
            let result=  await getNextCodeNicList(item.code);
            //console.log(result);
            if(result.code==20000){
              econoIndustryClass.value=result.data;
            }
        }
        //获取企业状态
        let stateList=ref([]);//企业状态列表
        const getEpStateFun=async()=>{
            
            let result=  await getEpState();
            //console.log(result);
            if(result.code==20000){
              stateList.value=result.data;
            }
        }
        let regionList=ref([]);//地区列表
        let regionTitle=ref('');//地区列表的标题
        //获取地区列表
        const getRegionListFun= async (item)=>{
            //console.log(item);
            // if(',110000,120000,310000,500000,'.indexOf(','+item.code+',')>-1){
            //     regionList.value=null;
            //     return;
            // }
            if(item.level==3){
                regionList.value=[];
                return
            }
            let params={
                parent:item.code
            }
            //获取下级地区列表
            let result=  await getCodeRegionList(params);
            //console.log(result);
            if(result.code==20000){
              regionList.value=result.data;
              if(regionList.value && regionList.value.length>0 && regionList.value[0].level==3){
                  regionTitle.value='区县';
              }
              else{
                  regionTitle.value='城市';
              }
            }
        }
        //获取父级标签
        let labelParentList=ref([]);//父级标签列表
        const getLabelparentListFun=async()=>{
            let params={
                parentId:'0',
                pageIndex:1,
                pageSize:1
            }
            let res=await getLabelList(params);
            //console.log(res);
            if(res.code==20000){
                labelParentList.value=res.data.dataList.map(t=>{
                    return {code:t.labelId,
                            name:t.labelName
                        }
                });
            }
        }
        //清空已筛选的条件
        const clearQueryFun=()=>{
            const keys = Object.keys(enterpriseQuery);
            keys.forEach((item) => {
                delete enterpriseQuery[item]
            });
            getEconoIndustryClassFun({code:''});
            if(regionCode.value){
                getRegionListFun({code:regionCode.value});
            }
            
            //console.log(enterpriseQuery);
            //企业查询
            getEnterpreiseListFun(1);
        }
        
        //获取查询条件
        const queryWhereFun=(key,item)=>{
            currentPage.value=1;
            //关键字查询
            if(key=="search"){
                if(iptSearch.value){
                    enterpriseQuery["search"]={name:iptSearch.value};
                }
                else{
                    delete enterpriseQuery["search"];
                }
            }
            //地区列表
            else if(key=="region"){
                if(item.level==2){
                    enterpriseQuery["city"]={code:item.code,name:item.name,level:item.level};
                }
                else
                {
                    enterpriseQuery["district"]={code:item.code,name:item.name,level:item.level};
                }
                getRegionListFun(item);
            }
            //行业分类
            else if(key=="econoIndustry"){
                if(item.level==1){
                    enterpriseQuery["industryCodeA"]=item;
                }
                else
                {
                    enterpriseQuery["industryCode"]=item;
                }
                getEconoIndustryClassFun(item);
            }
            else{
                enterpriseQuery[key]=item;
            }
            //企业查询
            getEnterpreiseListFun(1);
        }
        //已选条件的删除
        const handleCloseFun=(item,key)=>{
            currentPage.value=1;
            if(key=="industryCodeA"){
                delete enterpriseQuery["industryCodeA"];
                delete enterpriseQuery["industryCode"];
            }
            else if(key=='industryCode'){
                delete enterpriseQuery["industryCode"];
            }
            else if(key=="city"){
                delete enterpriseQuery["city"];
                delete enterpriseQuery["district"];
                getRegionListFun(province);
            }
            else if(key=="district"){
                delete enterpriseQuery["district"];
                getRegionListFun(enterpriseQuery["city"]?enterpriseQuery["city"]:(city?city:province));
            }
            if(key.indexOf("industryCode")>-1){
              getEconoIndustryClassFun({code:item.parent});
            }
            delete enterpriseQuery[key];

            //企业查询
            getEnterpreiseListFun(1);
        }
        let enterpriseList=ref(null);//企业列表
        let labelList=ref([]);//企业标签列表
        //获取企业列表,分页
        const getEnterpreiseListFun= async(type)=>{
            loadingShow.value=true
            enterpriseList.value=[];
            //cancel();   // 取消所有正在发送请求的请求
            
            //console.log(enterpriseQuery)
            let params={pageIndex:currentPage.value,pageSize:pageSize.value,province:province.code,state:1};
            if(city){
                params['city']=city.code;
            }
            let keyList='';
            for(let key in enterpriseQuery){
                keyList=keyList+','+key;
                //console.log(keyList);
                if(key=='search'){
                    params[key]=enterpriseQuery[key].name;
                }else if(key=='epStyle' || key=='state'){
                    params[key]=enterpriseQuery[key].id;
                }
                else{
                    params[key]=enterpriseQuery[key].code.toString();
                }
            }
            //console.log(params);

            if(type==1){//type=1,表示需要企业总数
                isMuchOfTotal.value=false;
                let resCount=await getRegionEpCount(params);
                console.log(resCount);
                if(resCount.code==20000){
                    total.value=resCount.data.totalCount;
                    console.log(total.value);
                }
                else if(resCount.code==30031){
                    isMuchOfTotal.value=true;
                    total.value=0;
                }
            }
            
            if(total.value>0){
                console.log(params);
                let result=await getRegionEpList(params);
                //console.log(result);
                if(result.code==20000){
                    labelList.value=[];
                    //total.value=result.data.totalCount;
                    enterpriseList.value=result.data;
                    let epIds=enterpriseList.value.map(t=>t.companyId)
                    if(epIds.length>0){   
                        getLabelListOfEpFun(epIds.join(','));
                    }
                }
            }
            loadingShow.value=false;
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'查询',JSON.stringify(params));
        }
        //单击分页
        const handleCurrentChangeFun=(current)=>{
            currentPage.value = current;
            //企业查询
            getEnterpreiseListFun(0);
        }
        //获取地区企业的标签列表
        const getLabelListOfEpFun=async(companyIds)=>{
            let params={
                objIds:companyIds,
                pageSize:1,
                pageIndex:1
            }
            //console.log(params);
            let result=await getLabelListOfRegionEp(params);
            //console.log(result);
            if(result.code==20000){
                labelList.value=result.data.dataList;
            }
        }
        //数据导出，只能导出前1000条数据，需要更多的数据请联系管理员
        const download=()=>{
            const _loading = ElLoading.service({
                lock: true,
                text: total.value>1000? '导出前1000条数据，更的数据请联系管理员，数据导出中，请稍后...':'数据导出中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const params={pageIndex:1,pageSize:1000,status:'1'};
            let keyList='';
            for(let key in enterpriseQuery){
                keyList=keyList+','+key;
                //console.log(keyList);
                if(key=='search'){
                    params[key]=enterpriseQuery[key].name;
                }else if(key=='epStyle'){
                    params['epStyle']=enterpriseQuery[key].id;
                }else if(key=='industryCodeB' && (keyList+',').indexOf(',industryCode,')<0){
                    params['industryCode']=enterpriseQuery[key].code.toString();
                }
                else{
                    params[key]=enterpriseQuery[key].code.toString();
                }
            }
            //console.log(params);
            // for(let key in enterpriseWhere){
            //     //console.log(key);
            //     if(key=='search'){
            //         params[key]=enterpriseWhere[key].name;
            //     }else if(key=='regCapitalList' || key=="estiblishTimeList" || key=="insuredPersonList"){//注册资本,成立日期，参保人数
            //          params[key]=[enterpriseWhere[key].code];
            //     }else if(key=='labelCodes'){//标签
            //          params[key]=enterpriseWhere[key].selfCode;
                     
            //     }else{
            //         params[key]=enterpriseWhere[key].code;
            //     }
            // }
            exportEpList(params).then(res => {
                _loading.close();
                //console.log(res);
                if(res.code==20000)
                {
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href =res.data //'http://172.25.146.86/report/'+res.data
                    //link.download = '重点企业列表.xls'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(link)
                } 
            })
        }
 
        
        //获取企业类型
        getEnterpriseTypeFun();
        //获取行业门类
        getEconoIndustryClassFun({code:''});
        //企业状态列表
        getEpStateFun();
        //父级标签列表
        getLabelparentListFun();
        //监听地区的改变
        watch(
            ()=>regionCode.value,
            async (newVal,oldVal)=>{
                //console.log('监听全局变量regionCode',newVal);
                if(!oldVal && newVal){
                    //执行
                    //获取地区的基本信息
                    //地区信息
                    await getRegionInfo(newVal).then((res)=>{
                        //console.log(res);
                        if(res.code==20000){
                            //console.log(res.data);
                            if(res.data.level=='1'){
                                province={code:res.data.code,name:res.data.name,level:res.data.level};
                            }
                            else if(res.data.level=='2')
                            {
                                city={code:res.data.code,name:res.data.name,level:res.data.level};
                                province={code:res.data.parent,level:'1'};
                            }
                            //获取下级地区
                            getRegionListFun({code:newVal});
                            //获取企业列表
                            getEnterpreiseListFun(1);
                        }
                    });
                    //记录用户操作日志
                    addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载','');
                }
            },
            {
                immediate:true,// 这个属性是重点啦
                //deep:true // 深度监听的参数
            }
        )
        // const cancel=()=>{ // 设置一个函数，在执行请求前先执行这个函数
        //     // 获取缓存的 请求取消标识 数组，取消所有关联的请求
        //     let cancelArr = window.axiosCancel;
        //     cancelArr.forEach((ele, index) => {
        //         console.log(ele);
        //         ele.cancel("取消了请求") // 在失败函数中返回这里自定义的错误信息
        //         delete window.axiosCancel[index]
        //     })
        // }
        onMounted(()=>{
         
        })
        return({
            enterpriseQuery,
            iptSearch,
            clearQueryFun,
            isQueryShow,
            queryWhereFun,
            regCapitalList,
            estiblishTimeList,
            insuredPersonList,
            econoIndustryClass,
            stateList,
            labelParentList,
            regionList,
            regionTitle,
            enterpriseType,
            handleCloseFun,
            loadingShow,
            enterpriseList,
            currentPage,
            pageSize,
            labelList,
            total,
            isMuchOfTotal,
            handleCurrentChangeFun,
            download,
            parseTime,
        })
    },  
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.filter{
    background-color:#fff;
    margin:20px;
    border-radius:2px;
    position:relative;
    min-width:1260px;
    border: 1px solid $bordercolor;
}
.filter-tips {
    border-bottom:1px solid $bordercolor;
    background: #fcfcfc;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 28px;
}
.span-txt{
    line-height: 30px;
    font-weight: bold;
    color: $specialtext-color;
}

.no_data_warp {
    padding: 80px 0 176px;
}
.no_data_top {
    display: flex;
    justify-content: center;
    align-items: center;
}
.no_data_title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 14px
}
.no_data_icon{
    font-size: 3rem;
    color:$text-color;
}
.expand-btn {
    width: 100px;
    height: 22px;
    position: absolute;
    left: 50%;
    bottom: -21px;
    transform: translateX(-50%);
    text-align: center;
    background: linear-gradient(-70deg, transparent 15px, #fff 0) right,linear-gradient(70deg, transparent 15px, #fff 0) left;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.expanded-mask {
    cursor: pointer;
    position: absolute;
    left: 2px;
    right: 2px;
    background-image: linear-gradient(0deg, #fff, rgba(255,255,255,0.2));
    height: 30px;
    bottom: 1px;
}

.card {
    position: relative;
    box-sizing: content-box;
}
.card .card-c {
    margin-right: 70px;
    height: 70px;
    color: gold;
    .number-text{
        font-size: 18px;
    }
}
.card .card-p {
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(../..//assets/piebg.0d174014.png);
    padding: 5px;
    width: 60px;
    height: 60px;

}
.pie {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  list-style: none;
  overflow: hidden;
  transform: rotate(0deg) /*针对mac safari浏览器兼容*/
}
.slice {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.slice-1 {
  transform: rotate(0deg) skewY(0deg);
  background:rgba(63, 148, 253, .6);
}
.slice-2 {
  transform: rotate(90deg) skewY(0deg);
  background: rgba(63, 148, 253, .6);
}
.slice-3 {
  transform: rotate(180deg) skewY(0deg);
  background: rgba(63, 148, 253,.6);
}
.pie {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    list-style: none;
    transform: rotate(0deg);
}
 .result-tips {
    line-height: 40px;
    background-color: #fcfcfc;
    border-bottom: 1px solid $bordercolor;
    padding: 0 20px;
    .el-button{
        float: right;
        margin-top: 4px;
    }
 }
 .result-list li{
    min-height: 100px;
    padding:20px 10px;
    // margin: 20px 0px;
    border-bottom: 1px solid $bordercolor;
 }
 .result-list li:hover{
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 5px -3px rgb(0 0 0 / 6%), 0 4px 12px 4px rgb(0 0 0 / 6%);
 }

 .result-list li .name {
    display: block;
    color:$text-color;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.3s ease;
    margin-bottom: 10px;
}
.result-list-content{
    position: relative;
    padding:0 10px 0 130px;
    min-height:100px;
    border-color: transparent;
}


.col .el-card{
    cursor: pointer;
}
.col .el-card:hover{
    border: 1px solid $themeColor;
}
.search-t{
    position:absolute;
    padding:6px;
    color:gray;
}
.search-content{
    padding-left: 80px;
    overflow: hidden;
    ul >li{
        float: left;
        padding:6px;
        cursor: pointer;
    }
    ul >li:hover{
       color: $specialtext-color;// $titcolor;
    }
    .el-input{
        width: 400px;
    }
}
.search-title{
    font-weight: 600;
    font-size: 14px;
    border-bottom:2px solid $themeColor;
    margin-bottom: 10px;
    span{
        background-color: $themeColor;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        letter-spacing:1px;
        color:#fff;
    }
}

.page-cus {
    white-space: nowrap;
    padding: 20px;
    color: #303133;
    font-weight: bold;
    background: #fff;
    
}
.result-list-content .site {
    position: absolute;
    text-align: right;
    right: 24px;
    top: 2px;
    line-height: 20px;
    font-size: 16px;
}
</style>